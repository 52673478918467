import React from "react";

const GraySeatAnnouncement = () => {
  return (
    <svg width="168" height="168" viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="84" cy="84" r="84" fill="white" fillOpacity="0.5" />
      <path d="M152.928 107.745L157.058 90.4413C159.208 81.6183 152.412 73.0522 143.293 73.0522H97.1834C92.6241 73.0522 88.9249 76.7356 88.9249 81.2756V149.204H165.402L152.928 107.745Z" fill="#4A4A4A" />
      <path d="M140.11 107.745L144.24 90.4413C146.39 81.6183 139.594 73.0522 130.476 73.0522H97.1834C92.6241 73.0522 88.9249 76.7356 88.9249 81.2756V149.204H152.584L140.11 107.745Z" fill="#292929" />
      <path d="M96.4948 21.6558C94.1721 9.3207 83.3328 0.326321 70.687 0.326321H46.2556C26.9857 0.326321 12.5333 17.8011 16.0604 36.5607L26.8137 93.1824H110.001L96.4948 21.6558Z" fill="#454545" />
      <path d="M70.6012 0.326321H62.0847C42.8148 0.326321 28.2764 17.8011 31.8895 36.5607L42.6427 93.1824H110.001L96.4091 21.5702C94.0864 9.32069 83.2471 0.326321 70.6012 0.326321Z" fill="url(#paint0_linear_2_8)" />
      <path d="M138.304 149.204L125.83 107.745H110.775L123.249 149.204H138.304Z" fill="#393939" />
      <path d="M110.775 107.745H64.1492L78.5155 149.204H123.249L110.775 107.745Z" fill="#292929" />
      <path d="M96.8394 149.204L84.3656 107.745H69.225L81.6988 149.204H96.8394Z" fill="#202020" />
      <path d="M133.658 93.268H26.8999V117.596H120.926C130.475 117.596 138.218 109.886 138.218 100.378V97.808C138.132 95.3239 136.153 93.268 133.658 93.268Z" fill="#676767" />
      <path d="M137.185 94.9812C136.325 93.9533 135.035 93.268 133.658 93.268H26.8999V106.545H120.926C128.411 106.545 134.863 101.748 137.185 94.9812Z" fill="#454545" />
      <path d="M36.363 22.0843C41.0945 21.913 45.7399 21.8273 50.4713 21.7417C55.2028 21.656 59.8482 21.656 64.5796 21.656C69.311 21.656 73.9565 21.656 78.6879 21.7417L85.742 21.8273C88.0647 21.913 90.4735 21.913 92.7962 21.9986C90.4735 22.0843 88.0647 22.17 85.742 22.17L78.6879 22.2556C73.9565 22.3413 69.311 22.3413 64.5796 22.3413C59.8482 22.3413 55.2028 22.3413 50.4713 22.2556C45.7399 22.3413 41.0084 22.2556 36.363 22.0843Z" fill="#252E4C" />
      <path d="M34.6425 37.7602C39.8901 37.5889 45.2237 37.5033 50.4713 37.4176C55.7189 37.3319 61.0525 37.3319 66.3001 37.3319C71.5477 37.3319 76.8813 37.3319 82.1289 37.4176L90.0433 37.5033C92.7101 37.5889 95.2909 37.5889 97.9577 37.6746C95.2909 37.7602 92.7101 37.8459 90.0433 37.8459L82.1289 37.9316C76.8813 38.0172 71.5477 38.0172 66.3001 38.0172C61.0525 38.0172 55.7189 38.0172 50.4713 37.9316C45.1376 38.0172 39.8901 37.9316 34.6425 37.7602Z" fill="#252E4C" />
      <path d="M37.1372 53.5217C42.3848 53.3504 47.7184 53.2647 52.966 53.1791C58.2136 53.0934 63.5472 53.0934 68.7948 53.0934C74.0424 53.0934 79.376 53.0934 84.6236 53.1791L92.5381 53.2647C95.2049 53.3504 97.7857 53.3504 100.452 53.436C97.7857 53.5217 95.2049 53.6074 92.5381 53.6074L84.6236 53.693C79.376 53.7787 74.0424 53.7787 68.7948 53.7787C63.5472 53.7787 58.2136 53.7787 52.966 53.693C47.7184 53.7787 42.4708 53.693 37.1372 53.5217Z" fill="#252E4C" />
      <path d="M40.2341 69.1976C45.4817 69.0263 50.7293 68.9406 55.9769 68.855C61.2245 68.7693 66.4721 68.7693 71.7197 68.7693C76.9673 68.7693 82.2149 68.7693 87.4625 68.855L95.2909 68.9406C97.8716 69.0263 100.538 69.0263 103.119 69.112C100.538 69.1976 97.8716 69.2833 95.2909 69.2833L87.4625 69.3689C82.2149 69.4546 76.9673 69.4546 71.7197 69.4546C66.4721 69.4546 61.2245 69.4546 55.9769 69.3689C50.6433 69.4546 45.3957 69.3689 40.2341 69.1976Z" fill="#252E4C" />
      <path d="M73.6983 107.745L77.8275 90.4413C79.9782 81.6183 73.1821 73.0522 64.0633 73.0522H17.9533C13.3939 73.0522 9.69482 76.7356 9.69482 81.2756V149.204H86.1721L73.6983 107.745Z" fill="#4A4A4A" />
      <path d="M60.8017 107.938L64.9309 90.6347C67.0816 81.8117 60.2855 73.2457 51.1667 73.2457H17.8746C13.3152 73.2457 9.61609 76.929 9.61609 81.469V149.398H73.2754L60.8017 107.938Z" fill="url(#paint1_linear_2_8)" />
      <defs>
        <linearGradient id="paint0_linear_2_8" x1="70.6686" y1="0.326321" x2="70.6686" y2="93.1824" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CDCDCD" />
          <stop offset="1" stopColor="#676767" />
        </linearGradient>
        <linearGradient id="paint1_linear_2_8" x1="41.4458" y1="73.2457" x2="41.4458" y2="149.398" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4C4C4C" />
          <stop offset="1" stopColor="#2E2E2E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GraySeatAnnouncement;