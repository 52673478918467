import React from 'react';

const RankingIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#FFCD20" fillOpacity="0.05" />
    <path d="M18.5767 5.03753C19.2101 3.90141 19.5267 3.33334 20.0002 3.33334C20.4736 3.33334 20.7903 3.90141 21.4236 5.03753L21.5874 5.33146C21.7674 5.65431 21.8574 5.81574 21.9977 5.92225C22.138 6.02876 22.3127 6.06829 22.6622 6.14737L22.9804 6.21936C24.2102 6.49762 24.8252 6.63675 24.9715 7.10721C25.1178 7.57767 24.6985 8.06788 23.8601 9.04831L23.6432 9.30195C23.4049 9.58056 23.2858 9.71986 23.2322 9.8922C23.1786 10.0645 23.1966 10.2504 23.2327 10.6221L23.2655 10.9605C23.3922 12.2686 23.4556 12.9227 23.0726 13.2135C22.6896 13.5042 22.1138 13.2391 20.9623 12.7089L20.6644 12.5718C20.3372 12.4211 20.1736 12.3458 20.0002 12.3458C19.8267 12.3458 19.6631 12.4211 19.3359 12.5718L19.038 12.7089C17.8865 13.2391 17.3108 13.5042 16.9277 13.2135C16.5447 12.9227 16.6081 12.2686 16.7349 10.9605L16.7677 10.6221C16.8037 10.2504 16.8217 10.0645 16.7681 9.8922C16.7145 9.71986 16.5954 9.58056 16.3571 9.30196L16.1402 9.04831C15.3018 8.06788 14.8826 7.57767 15.0289 7.10721C15.1752 6.63675 15.7901 6.49762 17.0199 6.21936L17.3381 6.14737C17.6876 6.06829 17.8623 6.02876 18.0026 5.92225C18.1429 5.81574 18.2329 5.65431 18.4129 5.33146L18.5767 5.03753Z" fill="#FFCD20" />
    <path d="M21.6668 16.6667H18.3335C15.9765 16.6667 14.798 16.6667 14.0657 17.3989C13.3335 18.1311 13.3335 19.3097 13.3335 21.6667V36.6667H26.6668V31.6667V21.6667C26.6668 19.3097 26.6668 18.1311 25.9346 17.3989C25.2024 16.6667 24.0239 16.6667 21.6668 16.6667Z" fill="#FFCD20" />
    <path d="M26.6665 31.6667V36.6667H36.6665V31.6667C36.6665 29.3096 36.6665 28.1311 35.9343 27.3989C35.202 26.6667 34.0235 26.6667 31.6665 26.6667C29.3095 26.6667 28.131 26.6667 27.3987 27.3989C26.6665 28.1311 26.6665 29.3096 26.6665 31.6667Z" fill="#EB5C24" />
    <path d="M12.6013 32.3989C11.869 31.6667 10.6905 31.6667 8.3335 31.6667C5.97647 31.6667 4.79796 31.6667 4.06573 32.3989C3.3335 33.1311 3.3335 34.3096 3.3335 36.6667H13.3335C13.3335 34.3096 13.3335 33.1311 12.6013 32.3989Z" fill="#EB5C24" />
  </svg>
);

export default RankingIcon;
