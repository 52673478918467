import React from "react";

const CupIcon = () => {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="40" height="40" rx="20" fill="white" fillOpacity="0.2" />
      <path d="M30 36.2667H28.3333V35.85C28.3333 34.0167 26.8333 32.5167 25 32.5167H21.25V28.7C20.8333 28.75 20.4167 28.7667 20 28.7667C19.5833 28.7667 19.1667 28.75 18.75 28.7V32.5167H15C13.1667 32.5167 11.6667 34.0167 11.6667 35.85V36.2667H10C9.31667 36.2667 8.75 36.8333 8.75 37.5167C8.75 38.2 9.31667 38.7667 10 38.7667H30C30.6833 38.7667 31.25 38.2 31.25 37.5167C31.25 36.8333 30.6833 36.2667 30 36.2667Z" fill="#EB5C24" />
      <path d="M9.1998 21.5C8.0998 21.0833 7.13314 20.4 6.36647 19.6333C4.81647 17.9166 3.7998 15.8666 3.7998 13.4666C3.7998 11.0666 5.68314 9.18329 8.08314 9.18329H9.01647C8.58314 10.0666 8.33314 11.05 8.33314 12.1V17.1C8.33314 18.6666 8.63314 20.15 9.1998 21.5Z" fill="#EB5C24" />
      <path d="M36.1998 13.4666C36.1998 15.8666 35.1831 17.9166 33.6331 19.6333C32.8665 20.4 31.8998 21.0833 30.7998 21.5C31.3665 20.15 31.6665 18.6666 31.6665 17.1V12.1C31.6665 11.05 31.4165 10.0666 30.9831 9.18329H31.9165C34.3165 9.18329 36.1998 11.0666 36.1998 13.4666Z" fill="#EB5C24" />
      <path d="M25.0002 5.43329H15.0002C11.3168 5.43329 8.3335 8.41662 8.3335 12.1V17.1C8.3335 23.55 13.5502 28.7666 20.0002 28.7666C26.4502 28.7666 31.6668 23.55 31.6668 17.1V12.1C31.6668 8.41662 28.6835 5.43329 25.0002 5.43329ZM24.7335 16.1833L23.7002 17.45C23.5335 17.6333 23.4168 18 23.4335 18.25L23.5335 19.8833C23.6002 20.8833 22.8835 21.4 21.9502 21.0333L20.4335 20.4333C20.2002 20.35 19.8002 20.35 19.5668 20.4333L18.0502 21.0333C17.1168 21.4 16.4002 20.8833 16.4668 19.8833L16.5668 18.25C16.5835 18 16.4668 17.6333 16.3002 17.45L15.2668 16.1833C14.6168 15.4166 14.9002 14.5666 15.8668 14.3166L17.4502 13.9166C17.7002 13.85 18.0002 13.6166 18.1335 13.4L19.0168 12.0333C19.5668 11.1833 20.4335 11.1833 20.9835 12.0333L21.8668 13.4C22.0002 13.6166 22.3002 13.85 22.5502 13.9166L24.1335 14.3166C25.1002 14.5666 25.3835 15.4166 24.7335 16.1833Z" fill="#FFCD20" />
      <path d="M24.7336 16.1833L23.7002 17.45C23.5336 17.6333 23.4169 18 23.4336 18.25L23.5336 19.8833C23.6002 20.8833 22.8836 21.4 21.9502 21.0333L20.4336 20.4333C20.2002 20.35 19.8002 20.35 19.5669 20.4333L18.0502 21.0333C17.1169 21.4 16.4002 20.8833 16.4669 19.8833L16.5669 18.25C16.5836 18 16.4669 17.6333 16.3002 17.45L15.2669 16.1833C14.6169 15.4166 14.9002 14.5666 15.8669 14.3166L17.4502 13.9166C17.7002 13.85 18.0002 13.6166 18.1336 13.4L19.0169 12.0333C19.5669 11.1833 20.4336 11.1833 20.9836 12.0333L21.8669 13.4C22.0002 13.6166 22.3002 13.85 22.5502 13.9166L24.1336 14.3166C25.1002 14.5666 25.3836 15.4166 24.7336 16.1833Z" fill="#EB5C24" />
    </svg>
  );
};

export default CupIcon;