import React from "react";

const NotificationIcon = () => {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M32.2333 24.6502L30.5667 21.8835C30.2167 21.2668 29.9 20.1002 29.9 19.4168V15.2002C29.9 9.76683 25.4833 5.3335 20.0333 5.3335C14.5833 5.3335 10.1667 9.76683 10.1667 15.2002V19.4168C10.1667 20.1002 9.84999 21.2668 9.49999 21.8668L7.81665 24.6502C7.14999 25.7668 6.99999 27.0002 7.41665 28.1335C7.81665 29.2502 8.76665 30.1168 9.99999 30.5335C13.2333 31.6335 16.6333 32.1668 20.0333 32.1668C23.4333 32.1668 26.8333 31.6335 30.0667 30.5502C31.2333 30.1668 32.1333 29.2835 32.5667 28.1335C33 26.9835 32.8833 25.7168 32.2333 24.6502Z" fill="#FFCD20" />
      <path d="M23.7499 6.0335C22.5999 5.5835 21.3499 5.3335 20.0333 5.3335C18.7333 5.3335 17.4833 5.56683 16.3333 6.0335C17.0499 4.6835 18.4666 3.8335 20.0333 3.8335C21.6166 3.8335 23.0166 4.6835 23.7499 6.0335Z" fill="#FFCD20" />
      <path d="M24.7167 33.8502C24.0167 35.7835 22.1667 37.1668 20 37.1668C18.6834 37.1668 17.3834 36.6335 16.4667 35.6835C15.9334 35.1835 15.5334 34.5168 15.3 33.8335C15.5167 33.8668 15.7334 33.8835 15.9667 33.9168C16.35 33.9668 16.75 34.0168 17.15 34.0502C18.1 34.1335 19.0667 34.1835 20.0334 34.1835C20.9834 34.1835 21.9334 34.1335 22.8667 34.0502C23.2167 34.0168 23.5667 34.0002 23.9 33.9502C24.1667 33.9168 24.4334 33.8835 24.7167 33.8502Z" fill="#FFCD20" />
    </svg>
  );
};

export default NotificationIcon;