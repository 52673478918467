import React from "react";

const HomeIcon = () => {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.5" d="M3.33325 20.84C3.33325 17.026 3.33325 15.119 4.19859 13.5381C5.06392 11.9572 6.64483 10.976 9.80664 9.01371L13.14 6.94495C16.4822 4.87065 18.1534 3.8335 19.9999 3.8335C21.8465 3.8335 23.5176 4.87065 26.8599 6.94494L30.1932 9.0137C33.355 10.976 34.9359 11.9572 35.8013 13.5381C36.6666 15.119 36.6666 17.026 36.6666 20.84V23.3751C36.6666 29.8766 36.6666 33.1273 34.714 35.1471C32.7613 37.1668 29.6186 37.1668 23.3333 37.1668H16.6666C10.3812 37.1668 7.2385 37.1668 5.28587 35.1471C3.33325 33.1273 3.33325 29.8766 3.33325 23.3751V20.84Z" fill="#FFCD20" />
      <path d="M15.7444 26.1624C15.1898 25.7513 14.407 25.8676 13.9959 26.4222C13.5848 26.9768 13.7011 27.7597 14.2557 28.1708C15.8764 29.3721 17.8585 30.0833 20.0001 30.0833C22.1417 30.0833 24.1237 29.3721 25.7444 28.1708C26.299 27.7597 26.4154 26.9768 26.0043 26.4222C25.5932 25.8676 24.8103 25.7513 24.2557 26.1624C23.0418 27.0622 21.5766 27.5832 20.0001 27.5832C18.4236 27.5832 16.9583 27.0622 15.7444 26.1624Z" fill="#FFCD20" />
    </svg>
  );
};

export default HomeIcon;